(self["webpackChunk_N_E"] = self["webpackChunk_N_E"] || []).push([[532],{

/***/ 16698:
/***/ (function(module) {

// extracted by mini-css-extract-plugin
module.exports = {"dropbtn":"fontsize_dropbtn__b0T8N","dropdown":"fontsize_dropdown__1HKT6","dropdown-content":"fontsize_dropdown-content__CM2Tz"};

/***/ }),

/***/ 1720:
/***/ (function() {

// extracted by mini-css-extract-plugin

/***/ })

}]);